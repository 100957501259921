import moment from 'moment';

export const getFirstWeekDays = (workingDaysInWeek, startDate) => {
  const firstDayOfMonth = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    1
  );
  const firstWeekDays = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(
      firstDayOfMonth.getTime() + i * 24 * 60 * 60 * 1000
    );
    const dayOfWeek = workingDaysInWeek.find(
      (day) =>
        day.day === currentDate.toLocaleString('default', { weekday: 'short' })
    );
    if (dayOfWeek) {
      const day = {
        day: dayOfWeek.day,
        startShift: dayOfWeek.startShift,
        endShift: dayOfWeek.endShift,
        date: currentDate,
      };
      firstWeekDays.push(day);
    }
  }

  return firstWeekDays;
};

/// which day bnelongs to the corresponding date property

const workingHours = [
  {
    date: '2023-03-01',
    startTime: '08:00',
    endTime: '16:00',
    type: 'Morning Shift',
  },
  {
    date: '2023-03-02',
    startTime: '16:00',
    endTime: '00:00',
    type: 'Evening Shift',
  },
];

const daysOfWeek = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];

workingHours.forEach((workingHour) => {
  const date = new Date(workingHour.date);
  const dayOfWeek = daysOfWeek[date.getDay()];
});

export const getShiftType = (startShift, endShift) => {
  let type = '';
  const trimmedStartShift = startShift.trim();
  const trimmedEndShift = endShift.trim();

  if (trimmedStartShift === '-' && trimmedEndShift === '-') {
    type = 'no shift';
  } else if (trimmedStartShift >= '6,00' && trimmedEndShift <= '16,00') {
    type = 'morning shift';
  } else if (trimmedStartShift >= '16,00' && trimmedEndShift <= '24,00') {
    type = 'evening shift';
  }

  return type;
};

export const generateTableHtml = (days, monthHours, leaveTotals) => {
  // TODO: Implement the logic to generate the HTML table
  // You can use the same logic you used for generating the table in the previous examples
  // Make sure to include days, monthHours, and leaveTotals in the HTML string
  const tableHtml = `
    <table>
      table
    </table>
  `;
  return tableHtml;
};

export const parseAndAdjustTime = (currentValue) => {
  const isValidTime = /^(?:[1-9]|1[0-9]|2[0-4])(?:,([0-5]?[0-9]))?$/.test(
    currentValue
  );

  let finalValue;

  if (isValidTime) {
    // Parse the entered hours and minutes
    const [, hours, minutes] =
      /^([1-9]|1[0-9]|2[0-4])(?:,([0-5]?[0-9]))?$/.exec(currentValue);

    // Adjust minutes based on input length: if single-digit, assume it's "30", otherwise pad to two digits
    const adjustedMinutes = minutes
      ? minutes.length === 1
        ? `${minutes}0`
        : minutes.padStart(2, '0')
      : '00';

    // Create the final formatted value
    finalValue = `${hours},${adjustedMinutes}`;
  } else {
    // Default to '-' for invalid input
    finalValue = '-';
  }

  return finalValue;
};

export const createDaysInMonth = (
  startMonth,
  workingDaysInWeek,
  workingDaysInSecondWeek = null,
  selectedOptions,
  startDay = 1
) => {
  const daysInMonth = [];
  let date = moment(startMonth).date(startDay).startOf('day');
  let endOfMonth = moment(date).endOf('month');

  while (date <= endOfMonth) {
    let dayOfWeek, startShift, endShift;

    if (selectedOptions.includes('dvosedmično radno vrijeme')) {
      // Check if the current date is in an even or odd week
      const isEvenWeek = moment(date).week() % 2 === 0;

      if (isEvenWeek) {
        // Even weeks use workingDaysInWeek
        dayOfWeek = workingDaysInWeek[moment(date).day()].dayOfWeek;
        startShift = workingDaysInWeek[moment(date).day()].startShift;
        endShift = workingDaysInWeek[moment(date).day()].endShift;
      } else {
        // Odd weeks use workingDaysInSecondWeek
        dayOfWeek = workingDaysInSecondWeek[moment(date).day()].dayOfWeek;
        startShift = workingDaysInSecondWeek[moment(date).day()].startShift;
        endShift = workingDaysInSecondWeek[moment(date).day()].endShift;
      }
    } else {
      // If not using 'dvosedmično radno vrijeme', use only workingDaysInWeek
      dayOfWeek = workingDaysInWeek[moment(date).day()].dayOfWeek;
      startShift = workingDaysInWeek[moment(date).day()].startShift;
      endShift = workingDaysInWeek[moment(date).day()].endShift;
    }

    const dateString = moment(date).format('YYYY-MM-DD');
    const type = getShiftType(startShift, endShift);

    daysInMonth.push({
      date: dateString,
      dayOfWeek,
      startShift,
      endShift,
      type,
    });

    date.add(1, 'day');
  }

  return daysInMonth;
};

export const groupDaysByWeek = (days) => {
  const weekDays = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];
  const weeks = [];
  let currentWeek = [];

  days?.forEach((day) => {
    if (currentWeek?.length === 0 && day?.dayOfWeek !== 'Pon') {
      // Fill initial empty days if the first day isn't 'Pon'
      const startIndex = weekDays.indexOf(day?.dayOfWeek);
      for (let i = 0; i < startIndex; i++) {
        currentWeek.push(null); // Push empty slots
      }
    }

    currentWeek.push(day);

    if (day.dayOfWeek === 'Ned' || day === days[days.length - 1]) {
      // End of the week or last day of the month
      weeks.push(currentWeek);
      currentWeek = [];
    }
  });

  console.log(weeks);

  return weeks;
};

export const groupDaysInOrder = (days) => {
  const weekDays = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];
  const orderedDays = [];

  if (days?.length > 0) {
    const firstDay = days[0]?.dayOfWeek; // Get the first working day
    const startIndex = weekDays.indexOf(firstDay);

    // Fill nulls for days before the first working day
    for (let i = 0; i < startIndex; i++) {
      orderedDays.push(null);
    }

    // Add all days sequentially
    days?.forEach((day) => {
      orderedDays.push(day);
    });
  }
  console.log(orderedDays);

  return orderedDays;
};
