import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import alertContext from '../contexts/alert/alertContext';

const ZaposleniciTable = () => {
  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [input, setInput] = useState('');

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const handleDelete = () => {
    if (employeeIdToDelete) {
      deleteEmployee(user.uid, employeeIdToDelete, setAlert, dispatch);
      setDeleteModalOpen(false);
    }
  };

  const GlobalContext = useContext(globalContext);
  const AuthContext = useContext(authContext);

  const { user } = AuthContext;
  const { employees, deleteEmployee, getEmployee, dispatch, companyDetails } =
    GlobalContext;

  const { employeeCount, pricingPlan } = companyDetails;

  const navigate = useNavigate();

  const onClickNavigateEdit = (currentUserUid, id) => {
    const fetchEmployee = async (id) => {
      const employee = await getEmployee(currentUserUid, id);

      dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
      navigate('/user/edit/' + id);
    };

    fetchEmployee(id);
  };

  const onClickEnterWorklog = (currentUserUid, id) => {
    const fetchEmployee = () => {
      navigate('/worklog/' + id);
    };

    fetchEmployee();
  };

  // Filter employees based on the input
  const filteredEmployees = employees.filter(
    (employee) =>
      employee.firstName.toLowerCase().includes(input.toLowerCase()) ||
      employee.lastName.toLowerCase().includes(input.toLowerCase())
  );

  useEffect(() => {
    console.log(pricingPlan, employeeCount);
  }, []);

  return (
    <div className=''>
      <div className='flex justify-between mt-3'></div>

      <div className='custom-grid-2'>
        {employees?.length > 0 && (
          <div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <i className='fa-solid fa-2x fa-users-line mr-1 text-gray-300'></i>
                <h3 className='my-2 font-semibold p-2 text-gray-300'>
                  Zaposlenici
                </h3>
              </div>
              <div>
                {employeeCount >= pricingPlan ? (
                  <div
                    className={`bg-gray-400 p-1 px-3 text-white rounded-full flex items-center text-sm w-full ${
                      employees.length > 0 && ''
                    } cursor-not-allowed`}
                    title='Dokupite za više radnika'
                  >
                    <i className='fa-sharp fa-solid fa-user-plus'></i>
                    <span>DODAJ ZAPOSLENIKA</span>
                  </div>
                ) : (
                  <Link to='/user'>
                    <div
                      className={`bg-green-500 p-1 px-4 rounded-full text-gray-100 hover:bg-green-700 flex items-center text-sm w-full relative right-[25px] ${
                        employees?.length > 0 && ''
                      }`}
                    >
                      <i className='fa-sharp fa-solid fa-user-plus'></i>
                      <span className='font-semibold'>Dodaj zaposlenika</span>
                    </div>
                  </Link>
                )}
              </div>
            </div>

            <table className='table w-[100%] table-dashboard'>
              <thead>
                <tr>
                  <th>Ime i Prezime</th>
                  <th>Radno Mjesto</th>
                  <th>Status</th>
                  <th className='hidden'></th>
                </tr>
              </thead>
              <tbody>
                {filteredEmployees?.map((item, index) => (
                  <tr key={index} className='relative'>
                    <td className='group '>
                      <div className='flex justify-between items-center'>
                        <div
                          className='flex items-baseline cursor-pointer'
                          onClick={() => onClickEnterWorklog(user.uid, item.id)}
                        >
                          <i className='fa-sharp fa-solid fa-user mr-2'></i>
                          {`${item.firstName} ${item.lastName}`}
                        </div>

                        <div className='ml-2'>
                          <i
                            onClick={() =>
                              onClickNavigateEdit(user.uid, item.id)
                            }
                            className='fa-sharp fa-solid fa-pen-to-square mr-2 cursor-pointer hover:text-green-800'
                          ></i>
                        </div>
                      </div>
                    </td>
                    <td className='text-center'>{item.department}</td>
                    <td className='text-center'>
                      {item?.endDate ? (
                        <span className='text-red-700'>Odjavljen</span>
                      ) : (
                        <span className=''>Prijavljen</span>
                      )}
                    </td>
                    <td className=''>
                      {' '}
                      <i
                        className='far fa-trash-alt cursor-pointer hover:text-gray-900 bg-transparent'
                        onClick={() => {
                          setEmployeeIdToDelete(item.id); // Set the employee for deletion
                          setDeleteModalOpen(true); // Open the modal
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {employees.length > 0 && (
          <div className='flex flex-col mt-[4rem]'>
            <div class='max-w-md mx-auto'>
              <div class='relative flex items-center w-full h-12 rounded-full focus-within:shadow-lg bg-white overflow-hidden'>
                <div class='grid place-items-center h-full w-12 text-gray-600 px-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    class='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                    />
                  </svg>
                </div>

                <input
                  class='peer h-full w-full min-w-[250px] outline-none text-sm text-gray-700 pr-2'
                  type='text'
                  id='search'
                  placeholder='Pretraži zaposlenika'
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default ZaposleniciTable;
