const loginFields = [
  {
    labelText: 'Email',
    labelFor: 'mail',
    id: 'email',
    name: 'email',
    type: 'email',
    autoComplete: 'email',
    placeholder: 'Email',
  },
  {
    labelText: 'Lozinka',
    labelFor: 'password',
    id: 'password',
    name: 'password',
    type: 'password',
    autoComplete: 'current-password',
    placeholder: 'Password',
  },
];

const signupFields = [
  {
    labelText: 'Naziv Firme',
    labelFor: 'companyName',
    id: 'companyName',
    name: 'companyName',
    type: 'text',
    placeholder: 'Naziv Firme',
  },
  {
    labelText: 'Email',
    labelFor: 'email',
    id: 'email',
    name: 'email',
    type: 'email',
    autoComplete: 'email',
    placeholder: 'Email address',
  },
  {
    labelText: 'Password',
    labelFor: 'password',
    id: 'password',
    name: 'password',
    type: 'password',
    autoComplete: 'current-password',
    placeholder: 'Password',
  },
  {
    labelText: 'Confirm Password',
    labelFor: 'Confirm Password',
    id: 'confirmPassword',
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Potvrdite password',
  },
];

const createFields = [
  {
    labelText: 'Ime',
    labelFor: 'Ime',
    id: 'ime',
    name: 'ime',
    type: 'text',
    autoComplete: 'ime',
    placeholder: 'ime',
  },
  {
    labelText: 'Prezime',
    labelFor: 'Prezime',
    id: 'prezime',
    name: 'prezime',
    type: 'text',
    autoComplete: 'prezime',
    placeholder: 'prezime',
  },
  {
    labelText: 'Spol',
    labelFor: 'Spol',
    id: 'spol',
    name: 'spol',
    type: 'text',
    autoComplete: 'prezime',
    placeholder: 'prezime',
  },
];

export { loginFields, signupFields };
