import React, { useContext } from 'react';
import authContext from '../contexts/auth/authContext';
import Logo from './Logo';
import Navbar from './Navbar';
import globalContext from '../contexts/satnica/globalContext';

const Header = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);

  const { companyDetails } = GlobalContext;

  const { companyName } = companyDetails;

  const { user } = AuthContext;
  return (
    <div className='border-b divide-slate-200 bg-[#fff]'>
      <div className='custom-container mx-auto'>
        <header className='flex justify-between items-center p-4'>
          <Logo />
          <h2 style={{ fontSize: '25px' }}>{companyName}</h2>
          {user && <Navbar />}
        </header>
      </div>
    </div>
  );
};

export default Header;
