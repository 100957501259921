import React, { useContext, useEffect } from 'react';

import ZaposleniciTable from '../components/ZaposleniciTable';
import authContext from '../contexts/auth/authContext';
import { auth } from '../config/config';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

const DashBoard = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const { user } = AuthContext;

  const {
    displayEmployees,
    dispatch,
    getCompanyDetails,
    companyDetails,
    employees,
  } = GlobalContext;

  const { companyName } = companyDetails;

  const currentUserUid = auth.currentUser.uid;

  // Display users from firebase
  useEffect(() => {
    if (user) {
      displayEmployees(currentUserUid, dispatch);
      getCompanyDetails(currentUserUid, dispatch);
      dispatch({ type: 'CLEAR_EMPLOYEE' });
    }
    //eslint-disable-next-line
  }, [currentUserUid]);

  return (
    <div className='px-5 pt-5 pb-10'>
      {companyName && employees.length === 0 && (
        <div>
          <div className=''>
            <h2 className='text-3xl text-gray-300 mt-10 mb-3'>
              Dobro došli na Satnicu
            </h2>
            <p className='text-gray-300'>
              Dodajte prvog zaposlenika kako bi se prikazala tabela.
            </p>
          </div>
          <Link to='/user'>
            <div
              className={`bg-green-500 p-2 px-4 mt-10 rounded-full  text-gray-100 inline-block hover:bg-green-700 items-center text-sm ${
                employees?.length > 0 && ''
              }`}
            >
              <i className='fa-sharp fa-solid fa-user-plus'></i>
              <span className='font-semibold'>Dodaj zaposlenika</span>
            </div>
          </Link>
        </div>
      )}
      {!companyName ? (
        <Loader />
      ) : (
        <>
          <div className='flex'>
            <ZaposleniciTable />
          </div>
        </>
      )}
    </div>
  );
};

export default DashBoard;
