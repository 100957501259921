import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { auth } from '../config/config';
import {
  getMonthByEmployee,
  updateMonthByEmployee,
  getEmployee,
} from '../api/api';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import {
  createDaysInMonth,
  getShiftType,
  groupDaysByWeek,
  parseAndAdjustTime,
} from '../helpers/helpers';
import _, { initial } from 'lodash';
import PrintTable from '../components/PrintTable';
import Loader from '../components/Loader';
import 'jspdf-autotable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import bs from 'date-fns/locale/bs'; // Import Croatian locale
import '../index.css';
import alertContext from '../contexts/alert/alertContext';

const WorkLogScreen = () => {
  const GlobalContext = useContext(globalContext);

  const { employee, dispatch } = GlobalContext;

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [loading, setLoading] = useState(true);

  const [loadingSmall, setLoadingSmall] = useState(false);

  const AuthContext = useContext(authContext);

  const currentUserUid = auth.currentUser.uid;

  const { user } = AuthContext;

  const { startDate } = employee;

  const { workingMonths } = employee;

  const param = useParams();

  const { employeeId } = param;

  const [isHasChanges, setIsHasChanges] = useState(false);

  const [days, setDays] = useState(workingMonths?.workingDays);

  const [prevDaysState, setPrevDaysState] = useState([]);

  const [loadingMap, setLoadingMap] = useState({});

  const [monthHours, setMonthHours] = useState(0);
  const [totalMonthHours, setTotalMonthHours] = useState(0);

  const [leaveTotals, setLeaveTotals] = useState({
    totalPreYearVacation: 0,
    totalVacation: 0,
    totalSickLeave: 0,
    totalPaidLeave: 0,
    totalUnpaidLeave: 0,
    totalNationalHoliday: 0,
  });

  const [printClick, setPrintClick] = useState(null);

  const [totalWorkingHoursByMonth, setTotalWorkingHoursByMonth] = useState();

  moment.locale('bs');

  const date = moment(startDate);

  const minMonthValue = date.format('YYYY-MM');

  const currentDate = moment().format('YYYY-MM');

  const maxDate = moment().add(2, 'month');
  const maxMonthValue = maxDate.format('YYYY-MM');

  const [selectedMonth, setSelectedMonth] = useState(currentDate);

  const formatedDate = moment(selectedMonth).format('YYYY-MM');

  const fetchEmployeeData = async () => {
    const newMonthData = copyDataToNewMonth();

    // Check if employee.endDate is not defined or is an empty string

    try {
      setLoading(true);
      if (employee && selectedMonth) {
        let result = await getMonthByEmployee(
          formatedDate,
          user.uid,
          employeeId,
          newMonthData,
          setAlert
        );

        setDays(result.workingMonths.workingDays);
        setPrevDaysState(result.workingMonths.workingDays);
        dispatch({ type: 'DISPLAY_EMPLOYEE', payload: result });

        setLoading(false);

        // Set the originalDays to the initial state when days are fetched
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeData();

    //eslint-disable-next-line
  }, [selectedMonth, employeeId]);

  useEffect(() => {
    dispatch({
      type: 'DISPLAY_EMPLOYEE',
      payload: {
        ...employee,
        workingMonths: { month: selectedMonth, workingDays: days },
      },
    });
    //eslint-disable-next-line
  }, [selectedMonth, isHasChanges]);

  const calculateDayHours = (day) => {
    const start =
      day.startShift && day.startShift !== '-'
        ? day.startShift.split(',').map(parseFloat)
        : [NaN, NaN];

    const end =
      day.endShift && day.endShift !== '-'
        ? day.endShift.split(',').map(parseFloat)
        : [NaN, NaN];

    if (isNaN(start[0]) || isNaN(start[1]) || isNaN(end[0]) || isNaN(end[1])) {
      return '-';
    }

    const startMinutes = start[0] * 60 + start[1];
    const endMinutes = end[0] * 60 + end[1];

    const totalMinutes = endMinutes - startMinutes;

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Use toFixed(1) to round to 1 decimal place
    return parseFloat((hours + minutes / 60).toFixed(1));
  };

  const calculateTotalMonthsHours = () => {
    let totalHours = 0;
    for (const day of days) {
      const dayHours = calculateDayHours(day);
      if (dayHours > 0) {
        totalHours += dayHours;
      }
    }
    setTotalMonthHours(totalHours);
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
  };

  useEffect(() => {
    if (employee?.endDate) setSelectedMonth(employee.endDate);
  }, []);

  const handleInputChange = (event, absoluteIndex, fieldName) => {
    const { value } = event.target;

    console.log(value);

    // Check if the input value is a positive integer up to 8 for specific fields
    const isValidInput =
      ([
        'preYearVacation',
        'vacation',
        'sickLeave',
        'paidLeave',
        'unpaidLeave',
        'overTimeHours',
        'bussinesTravelHours',
        'nationalHoliday',
      ].includes(fieldName) &&
        /^(?:[1-8]|)$/.test(value)) ||
      fieldName === 'startShift' ||
      fieldName === 'endShift' ||
      value === '';

    if (isValidInput || value === '') {
      const updatedDays = [...days];
      updatedDays[absoluteIndex][fieldName] = value;

      // Reset values in other fields based on the entered field
      const resetFields = [
        'preYearVacation',
        'vacation',
        'sickLeave',
        'paidLeave',
        'unpaidLeave',
        'overTimeHours',
        'bussinesTravelHours',
        'nationalHoliday',
      ];

      resetFields.forEach((field) => {
        if (field !== fieldName) {
          updatedDays[absoluteIndex][field] = '';
        }
      });

      // Check if any of the vacation, preYearVacation, or sickLeave inputs are filled
      const day = updatedDays[absoluteIndex];

      if (
        day.vacation ||
        day.preYearVacation ||
        day.sickLeave ||
        day.nationalHoliday
      ) {
        day.startShift = '-';
        day.endShift = '-';
      }

      // Get the startShift and endShift values from the updated day
      const startShift = updatedDays[absoluteIndex].startShift;
      const endShift = updatedDays[absoluteIndex].endShift;

      // Call the getShiftType function to determine the shift type
      const shiftType = getShiftType(startShift, endShift);

      // Update the shift type in the day object
      updatedDays[absoluteIndex].type = shiftType;

      setDays(updatedDays); // Update the full days array

      calculateTotalMonthsHours(); // Recalculate the total month hours
    }
  };

  useEffect(() => {
    if (days) {
      calculateTotalMonthsHours();
    }
    //eslint-disable-next-line
  }, [days, selectedMonth]);

  useEffect(() => {
    days && calculateLeaveDays();
    //eslint-disable-next-line
  }, [days]);

  const calculateLeaveDays = () => {
    let totalPreYearVacation = 0;
    let totalVacation = 0;
    let totalSickLeave = 0;
    let totalPaidLeave = 0;
    let totalUnpaidLeave = 0;
    let totalOverTimeHours = 0;
    let totalBussinesTravelHours = 0;
    let totalNationalHoliday = 0;

    for (const day of days) {
      const preYearVacation = parseInt(day.preYearVacation) || 0;
      const vacation = parseInt(day.vacation) || 0;
      const sickLeave = parseInt(day.sickLeave) || 0;
      const paidLeave = parseInt(day.paidLeave) || 0;
      const unpaidLeave = parseInt(day.unpaidLeave) || 0;
      const overTimeHours = parseInt(day.overTimeHours) || 0;
      const bussinesTravelHours = parseInt(day.bussinesTravelHours) || 0;
      const nationalHoliday = parseInt(day.nationalHoliday) || 0;

      // If sickLeave is entered, count it as a full day (8 hours)
      if (sickLeave > 0) {
        totalSickLeave += sickLeave; // Assuming a full day for sick leave
      }

      totalPreYearVacation += preYearVacation;
      totalVacation += vacation;
      totalPaidLeave += paidLeave;
      totalUnpaidLeave += unpaidLeave;
      totalOverTimeHours += overTimeHours;
      totalBussinesTravelHours += bussinesTravelHours;
      totalNationalHoliday += nationalHoliday;
    }

    setLeaveTotals({
      totalPreYearVacation,
      totalVacation,
      totalSickLeave,
      totalPaidLeave,
      totalUnpaidLeave,
      totalOverTimeHours,
      totalBussinesTravelHours,
      totalNationalHoliday,
    });
  };

  const handleBlur = async (event, day) => {
    const currentValue = event.target.value;
    const { name } = event.target;
    const date = day.date;

    try {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [name]: date,
        isActive: true,
      }));

      // Apply the special logic for startShift and endShift
      let finalValue;

      // Apply the special logic for startShift and endShift
      if (name === 'startShift' || name === 'endShift') {
        if (currentValue !== '-') {
          finalValue = parseAndAdjustTime(currentValue);
        } else {
          finalValue = '-';
        }
      } else {
        finalValue = currentValue;
      }

      // Set the value in the local state
      setDays((prevDays) => {
        return prevDays.map((day) => {
          if (day.date === date) {
            return {
              ...day,
              [name]: finalValue,
            };
          }

          return day;
        });
      });

      // Construct the data object for Firestore update
    } catch (error) {
      console.error('Error handling blur:', error);
    }

    /*    setIsHasChanges(true); */

    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [name]: date,
      isActive: false,
    }));
  };

  const handlePrint = async () => {
    setPrintClick(true);
    setTimeout(() => setPrintClick(null), 300);
  };

  const updateMonthDataHandeler = async () => {
    /*  setPrevDays([...days]); */
    try {
      setLoadingSmall(true);
      if (user.uid && employeeId && selectedMonth) {
        // Check if there are changes in the working days

        const updatedMonthData = await updateMonthByEmployee(
          user.uid,
          employee.employeeId,
          formatedDate,
          days,
          setAlert
        );

        if (updatedMonthData) {
          // Handle success, update the state, or perform other actions
          console.log('Uspješno ažuriran mjesec:', selectedMonth);
          /*  setPrevDays([...days]); */
          /*        } else {
            // Handle failure or show an error message
            console.error('Ažuriranje mjeseca nije uspjelo.');
          } */
        } else {
          setAlert(
            'Greška na serveru',
            'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
          );
          setLoadingSmall(false);
        }
        setLoadingSmall(false);
      }
    } catch (error) {
      console.error('Error updating month data:', error);
      setLoading(false);
    }
  };

  const { employeeId: id } = param;

  const navigate = useNavigate();

  const handleEdit = () => {
    const fetchEmployee = async (id) => {
      const employee = await getEmployee(currentUserUid, id);

      dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
      navigate('/user/edit/' + id);
    };

    fetchEmployee(id);
  };

  const copyDataToNewMonth = () => {
    if (days) {
      // Napravit da moze napraviti novi mjesec kada dodje taj mjesec;

      // Find the index of the first Sunday in the current month
      const firstSundayIndex = days.findIndex(
        (day) => moment(day.date, 'YYYY-MM-DD').isoWeekday() === 7 // Nedjelja (Sunday) is 7
      );

      if (firstSundayIndex !== -1) {
        // Calculate the index of the last day (Saturday) in the same week
        const lastSaturdayIndex = firstSundayIndex + 6;

        // Extract the first week of the current month from Sunday to Saturday
        const firstWeekDays = days.slice(
          firstSundayIndex,
          lastSaturdayIndex + 1
        );

        // Set the date property of each object in the first week to an empty string
        const modifiedFirstWeek = firstWeekDays.map((day) => ({
          ...day,
          date: '',
        }));

        // Create a date object for the 1st day of the selected month
        /* let monthDate = moment().year(year).month(monthIndex).date(1); */

        // Parse the selectedMonth to get the correct year and month
        const monthDate = moment(selectedMonth).startOf('month');

        const newMonth = createDaysInMonth(
          monthDate,
          modifiedFirstWeek,
          null,
          '',
          1
        );

        const newMonthData = {
          month: monthDate.format('YYYY-MM'),
          workingDays: [...newMonth],
        };

        return newMonthData;
      } else {
        // If there is no Sunday in the current month
        console.log('Nedjelja nije pronadjena za u ovom mjesecu');
        // Handle this case accordingly, such as by using partial data or displaying a message to the user
      }
    }
  };

  useEffect(() => {
    getWorkingHoursInMonth(selectedMonth);
  }, [selectedMonth]);

  const getWorkingHoursInMonth = (selectedMonth) => {
    const hoursPerWorkDay = 8; // Standard working hours per day
    let workingDaysCount = 0;

    // Create a moment object from the Date
    const momentDate = moment(selectedMonth);
    const year = momentDate.year(); // Get the year
    const month = momentDate.month(); // Get the month (0-indexed)

    // Set the first and last date of the specified month
    const firstDay = moment(`${year}-${month + 1}-01`); // First day of the month
    const lastDay = firstDay.clone().endOf('month'); // Last day of the month

    // Initialize the day variable
    let day = firstDay.clone(); // Start from the first day

    // Iterate through each day of the month
    while (day.isBefore(lastDay.clone().add(0, 'days'))) {
      // Ensure to include the last day
      // Check if the day is a business day (Monday to Friday)
      if (day.isoWeekday() < 6) {
        // 1 (Monday) to 5 (Friday) are working days
        workingDaysCount++;
      }
      day.add(1, 'days'); // Move to the next day
    }

    // Calculate the total working hours for the month
    const totalWorkingHours = workingDaysCount * hoursPerWorkDay;
    setTotalWorkingHoursByMonth(totalWorkingHours);
  };

  const [displayWeekNumber, setDisplayWeekNumber] = useState(1);

  const [daysPerPage, setDaysPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(0);

  const newDays = groupDaysByWeek(days);

  // Get days for the current page
  let paginatedDays = days?.slice(
    currentPage * daysPerPage,
    (currentPage + 1) * daysPerPage
  );

  // Adjust daysPerPage based on displayWeekNumber
  useEffect(() => {
    if (displayWeekNumber === 1 && days) {
      setDaysPerPage(7); // One week
    } else if (displayWeekNumber === 2) {
      setDaysPerPage(16); // Two weeks
    } else if (displayWeekNumber === 3) {
      setDaysPerPage(31);
    } else {
      setDaysPerPage(7 * displayWeekNumber); // Multi-week (e.g., more than 7)
    }
    setCurrentPage(0); // Reset to the first page whenever the week display changes
    //eslint-disable-next-line
  }, [displayWeekNumber]);

  // Calculate total pages
  const totalPages = Math.ceil(days?.length / daysPerPage);

  // Handle page navigation
  const goToNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage((prev) => prev + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) setCurrentPage((prev) => prev - 1);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedMonth]);

  return (
    <div className='mx-auto container custom-container'>
      <div className='pt-5 p-5 relative'>
        {loading && employee ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            <div style={{ display: 'none' }}>
              <PrintTable
                days={days}
                leaveTotals={leaveTotals}
                monthHours={monthHours}
                totalMonthHours={totalMonthHours}
                handlePrint={handlePrint}
                clickPrint={printClick}
                selectedMonth={selectedMonth}
                calculateDayHours={calculateDayHours}
              />
            </div>

            {employee?.endDate && (
              <div className='mb-2 flex w-[300px] px-2 py-1 bg-black text-red-700'>
                <p className='font-semibold'>Radnik odjavljen s danom: </p>
                <span className='font-semibold ml-1'>
                  {moment(employee?.endDate).format('DD-MM-YYYY')}
                </span>
              </div>
            )}

            <div className='flex justify-between text-[#eee]'>
              <div className='flex items-center font-semibold '>
                <Link to='/dashboard' className='text-[#eee]'>
                  <div className='flex items-center'>
                    <i className='fa-solid fa-2x fa-users-line mr-1 text-white'></i>
                    <h3 className='my-2 font-semibold p-2 hover:text-green-500'>
                      Zaposlenici {'>'}
                    </h3>
                  </div>
                </Link>

                <h3>{` ${employee?.firstName} ${employee?.lastName}`}</h3>
              </div>
              <div className='text-center flex items-center  '>
                <div className='custom-datapicker'>
                  <DatePicker
                    selected={new Date(selectedMonth)}
                    minDate={new Date(minMonthValue)}
                    maxDate={
                      employee.endDate
                        ? new Date(employee?.endDate)
                        : new Date(maxMonthValue)
                    }
                    onChange={handleMonthChange} // Handle month change
                    dateFormat='MMMM yyyy' // Set the desired date format
                    locale={bs} // Set the Croatian locale
                    showMonthYearPicker // Show only month and year picker
                    className='ml-2 text-lg font-semibold text-blue-900'
                  />
                  {/* <span className="calendar-icon">&#128197;</span> {/* Calendar icon */}
                </div>
              </div>

              <div className='flex items-center justify-end'>
                <div className='flex items-center justify-end mr-3'>
                  <button
                    className={`bg-green-700 hover:bg-green-500 text-[#eee] text-sm font-bold py-2 px-4 rounded-full mr-5  ${
                      loadingSmall && `opacity-30`
                    }
        }`}
                    onClick={updateMonthDataHandeler}
                    disabled={loadingSmall}
                  >
                    Ažuriraj Mjesec
                  </button>

                  <div
                    onClick={handleEdit}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <i className='fa fa-2x  fa-edit ml-2 mr-2 text-[#eee]'></i>
                    <span>Edit</span>
                  </div>

                  <div
                    onClick={handlePrint}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <i className='fa fa-2x  fa-print ml-2 mr-2 text-[#eee]'></i>
                    <span>Print</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='absolute top-[15px]'>
              {loadingMap['isActive'] === true ||
                (loadingSmall && (
                  <div className=''>
                    <Loader />
                  </div>
                ))}
            </div>
            <div>
              <label htmlFor='weekSelector' className='text-[#eee]'>
                Prikaz Tabele:{' '}
              </label>
              <select
                id='weekSelector'
                className='bg-green-500 p-1'
                value={displayWeekNumber}
                onChange={(e) => setDisplayWeekNumber(Number(e.target.value))}
              >
                <option value={1}>Jedna sedmica</option>
                <option value={2}>Pola mjeseca</option>
                <option value={3}>Cijeli mjesec</option>
              </select>
            </div>

            <div>
              <table className='table-fixed mt-10'>
                <thead className='bg-gray-300 border border-1'>
                  <tr className='bg-800-gray text-800-gray'>
                    <th
                      className='px-4 py-3 font-semibold'
                      data-tooltip-id='datum-tooltip'
                      data-tooltip-content='Datum rada'
                    >
                      Datum
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='dolazak-tooltip'
                      data-tooltip-content='Vrijeme dolaska na posao'
                    >
                      Dolazak
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='odlazak-tooltip'
                      data-tooltip-content='Vrijeme odlaska s posla'
                    >
                      Odlazak
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='ukupno-tooltip'
                      data-tooltip-content='Ukupno radno vrijeme za taj dan'
                    >
                      Ukupno
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='go-tooltip'
                      data-tooltip-content='Godišnji odmor'
                    >
                      Go
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='bolovanje-tooltip'
                      data-tooltip-content='Odsutnost zbog bolovanja'
                    >
                      Bolovanje
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='pla-tooltip'
                      data-tooltip-content='Plaćeni dopust'
                    >
                      Pla
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='nep-tooltip'
                      data-tooltip-content='Neplaćeni dopust'
                    >
                      Nep
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='sluz-tooltip'
                      data-tooltip-content='Službeni put'
                    >
                      Služ
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='prek-tooltip'
                      data-tooltip-content='Prekovremeni rad'
                    >
                      Prek
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='dp-tooltip'
                      data-tooltip-content='Državni praznik'
                    >
                      DP
                    </th>
                  </tr>
                </thead>
                {/* Dodajte Tooltip komponentu za svaki `data-tooltip-id` */}
                <Tooltip
                  id='datum-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='dolazak-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='odlazak-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='ukupno-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='go-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='bolovanje-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='pla-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='nep-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='sluz-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='prek-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />
                <Tooltip
                  id='dp-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                />

                <tbody className='bg-white'>
                  {paginatedDays?.map((day, pageIndex) => {
                    // Skip rendering if day is null
                    if (!day) return null;

                    const absoluteIndex = currentPage * daysPerPage + pageIndex; // Calculate the absolute index

                    return (
                      <tr key={absoluteIndex}>
                        <td
                          className={`border px-4 py-2 ${
                            day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned'
                              ? 'bg-red-300'
                              : 'bg-blue-100'
                          }`}
                        >
                          {day.dayOfWeek && new Date(day.date).getDate()}
                          {'.' + day.dayOfWeek}
                        </td>
                        <td className='border px-4 py-2 relative'>
                          <input
                            className='w-full'
                            name='startShift'
                            type='text'
                            value={day.startShift || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'startShift'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2 relative'>
                          <input
                            className='w-full'
                            type='text'
                            name='endShift'
                            value={day.endShift || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'endShift'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          {calculateDayHours(day)}
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='vacation'
                            value={day.vacation || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'vacation'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='sickLeave'
                            value={day.sickLeave || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'sickLeave'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='paidLeave'
                            value={day.paidLeave || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'paidLeave'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='unpaidLeave'
                            value={day.unpaidLeave || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'unpaidLeave'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='bussinesTravelHours'
                            value={day.bussinesTravelHours || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'bussinesTravelHours'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='overTimeHours'
                            value={day.overTimeHours || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'overTimeHours'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                        <td className='border px-4 py-2'>
                          <input
                            className='w-full'
                            type='text'
                            name='nationalHoliday'
                            value={day.nationalHoliday || ''}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                absoluteIndex,
                                'nationalHoliday'
                              )
                            }
                            onBlur={(e) => handleBlur(e, day)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                className={`pagination-controls mt-4 flex ${
                  currentPage !== 0 ? 'justify-between' : 'justify-end'
                }`}
              >
                {currentPage !== 0 && (
                  <div
                    className='text-[#eee] font-semibold'
                    onClick={goToPreviousPage}
                  >
                    <i className='fa-solid fa-circle-left'></i>
                    <span
                      className='px-4 py-2 cursor-pointer'
                      disabled={currentPage === 0}
                    >
                      Predthodna sedmica
                    </span>
                  </div>
                )}

                {currentPage < totalPages - 1 && (
                  <div
                    className='text-[#eee] font-semibold cursor-pointer'
                    onClick={goToNextPage}
                  >
                    <i className='fa-solid fa-circle-right'></i>
                    <span
                      className='px-4 py-2'
                      disabled={currentPage === totalPages - 1}
                    >
                      Slijedeća sedmica
                    </span>
                  </div>
                )}
              </div>
              <table className='w-full mt-10'>
                <thead className='bg-gray-300' style={{ display: 'none ' }}>
                  <tr className='bg-800-gray text-800-gray'>
                    <th
                      className='px-4 py-3 font-semibold'
                      data-tooltip-id='datum-tooltip'
                      data-tooltip-content='Datum rada'
                    >
                      Datum
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='dolazak-tooltip'
                      data-tooltip-content='Vrijeme dolaska na posao'
                    >
                      Dolazak
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='odlazak-tooltip'
                      data-tooltip-content='Vrijeme odlaska s posla'
                    >
                      Odlazak
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='ukupno-tooltip'
                      data-tooltip-content='Ukupno radno vrijeme za taj dan'
                    >
                      Ukupno
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='go-tooltip'
                      data-tooltip-content='Godišnji odmor'
                    >
                      Go
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='bolovanje-tooltip'
                      data-tooltip-content='Odsutnost zbog bolovanja'
                    >
                      Bolovanje
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='pla-tooltip'
                      data-tooltip-content='Plaćeni dopust'
                    >
                      Pla
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='nep-tooltip'
                      data-tooltip-content='Neplaćeni dopust'
                    >
                      Nep
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='sluz-tooltip'
                      data-tooltip-content='Službeni put'
                    >
                      Služ
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='prek-tooltip'
                      data-tooltip-content='Prekovremeni rad'
                    >
                      Prek
                    </th>
                    <th
                      className='px-4 py-2 font-semibold'
                      data-tooltip-id='dp-tooltip'
                      data-tooltip-content='Državni praznik'
                    >
                      DP
                    </th>
                  </tr>
                </thead>
                <tfoot className='mt-5'>
                  <tr className='bg-gray-600 text-white'>
                    <td className='border px-4 py-2'>Ukupno:</td>
                    <td className='border px-4 py-2'></td>
                    <td className='border px-4 py-2'></td>
                    <td className='border px-4 py-2'>{monthHours}</td>
                    {/*   <td className='border px-4 py-2'>
                    {leaveTotals.totalPreYearVacation}
                  </td> */}
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalVacation}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalSickLeave}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalPaidLeave}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalUnpaidLeave}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalBussinesTravelHours}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalOverTimeHours}
                    </td>
                    <td className='border px-4 py-2'>
                      {leaveTotals.totalNationalHoliday}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className='mt-8 month-total'>
              <div className='flex justify-between text-[#eee]'>
                <p>
                  Mjesečno radio/la sati:{' '}
                  <span className='font-semibold mt-2 text-[#eee]'>
                    {totalMonthHours +
                      leaveTotals.totalOverTimeHours -
                      leaveTotals.totalUnpaidLeave}{' '}
                  </span>
                </p>
                <p className='mt-2'>
                  Radni Sati u Mjesecu:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {totalWorkingHoursByMonth}
                  </span>
                </p>
              </div>

              {leaveTotals.totalSickLeave !== 0 && (
                <p>
                  Bolovanje:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalSickLeave}
                  </span>
                </p>
              )}
              {leaveTotals.totalVacation !== 0 && (
                <p>
                  Godišnji odmor:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals?.totalVacation}{' '}
                  </span>
                </p>
              )}
              {leaveTotals.totalPreYearVacation !== 0 && (
                <p>
                  Godišnji odmor iz prošle godine:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals?.totalPreYearVacation}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalPaidLeave !== 0 && (
                <p>
                  Plaćeno odsustvo:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalPaidLeave}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalUnpaidLeave !== 0 && (
                <p>
                  Neplaćeno odsustvo:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalUnpaidLeave}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalBussinesTravelHours !== 0 && (
                <p>
                  Službeno putovanje:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalBussinesTravelHours}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalOverTimeHours !== 0 && (
                <p>
                  Prekovremeni rad:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalOverTimeHours}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalNationalHoliday !== 0 && (
                <p>
                  Državni praznik:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalNationalHoliday}
                    {'  '}
                  </span>
                </p>
              )}
              <p className='mt-2 text-[#eee]'>
                Ukupno:{' '}
                <span className='font-semibold text-[#eee]'>
                  {totalMonthHours -
                    leaveTotals.totalUnpaidLeave +
                    leaveTotals.totalSickLeave +
                    leaveTotals.totalVacation +
                    leaveTotals.totalPreYearVacation +
                    leaveTotals.totalOverTimeHours +
                    leaveTotals.totalNationalHoliday}
                  {'  '}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkLogScreen;
