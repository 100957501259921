import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { createDaysInMonth, parseAndAdjustTime } from '../helpers/helpers';
import { useParams } from 'react-router-dom';

const WeekdaysTable = ({ inputStyle, month, setWorkingMonthsInYear }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const param = useParams();

  const { employeeId } = param;

  const today = moment();

  const daysOfWeek = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];

  const [workingDaysInWeek, setWorkingDaysInWeek] = useState([
    {
      date: '',
      dayOfWeek: 'Ned',
      startShift: '-',
      endShift: '-',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Pon',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Uto',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Sri',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Čet',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Pet',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Sub',
      startShift: '-',
      endShift: '-',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
  ]);

  const sortedWorkingDaysInWeek = workingDaysInWeek.slice().sort((a, b) => {
    return daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek);
  });

  const [workingDaysInSecondWeek, setWorkingDaysInSecondWeek] = useState(
    workingDaysInWeek.map((day) => ({
      date: day.date,
      dayOfWeek: day.dayOfWeek,
      startShift: day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned' ? '-' : '',
      endShift: day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned' ? '-' : '',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    }))
  );

  const sortedWorkingDaysInSecondWeek = workingDaysInSecondWeek
    .slice()
    .sort((a, b) => {
      return daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek);
    });

  useEffect(() => {}, [workingDaysInSecondWeek]);

  const getWorkingMonthsInYear = (
    startYear,
    workingDaysInWeek,
    workingDaysInSecondWeek,
    selectedOptions,
    startDate
  ) => {
    const monthsInYear = [];
    const startMonth = moment(startDate);
    const startMonthIndex = startMonth.month();
    const currentYear = moment().year();

    for (let year = startYear; year <= currentYear; year++) {
      const startMonthIndexForYear = year === startYear ? startMonthIndex : 0;
      const endMonthIndexForYear =
        year === currentYear ? today.month() + 1 : 11;

      for (let i = startMonthIndexForYear; i <= endMonthIndexForYear; i++) {
        const monthDate = moment().year(year).month(i).date(1);
        const monthName = `${i + 1}`.padStart(2, '0');

        let daysInMonth;

        if (i === startMonthIndex && year === startYear) {
          const startDay = startMonth.date();

          daysInMonth = createDaysInMonth(
            startMonth,
            workingDaysInWeek,
            workingDaysInSecondWeek,
            selectedOptions,
            startDay
          );
        } else {
          daysInMonth = createDaysInMonth(
            monthDate,
            workingDaysInWeek,
            workingDaysInSecondWeek,
            selectedOptions
          );
        }

        monthsInYear.push({
          month: `${year}-${monthName}`,
          workingDays: daysInMonth,
        });
      }
    }

    setWorkingMonthsInYear(monthsInYear);

    return monthsInYear;
  };

  useEffect(() => {
    if (month) {
      getWorkingMonthsInYear(
        month.getFullYear(),
        workingDaysInWeek,
        workingDaysInSecondWeek,
        selectedOptions,
        month
      );
    }
    //eslint-disable-next-line
  }, [month, workingDaysInWeek, workingDaysInSecondWeek, selectedOptions]);

  const handleChange = (event, dayOfWeek, setWorkingDaysHandler) => {
    const currentValue = event.target.value;
    setWorkingDaysHandler((prevState) => {
      return prevState.map((day) => {
        if (day.dayOfWeek === dayOfWeek) {
          return {
            ...day,
            [event.target.name]: currentValue,
          };
        }
        return day;
      });
    });
  };

  const handleBlur = (event, dayOfWeek, setWorkingDaysHandler) => {
    const currentValue = event.target.value;

    // Reuse the logic from parseAndAdjustTime

    const finalValue = parseAndAdjustTime(currentValue);

    setWorkingDaysHandler((prevState) => {
      return prevState.map((day) => {
        if (day.dayOfWeek === dayOfWeek) {
          return {
            ...day,
            [event.target.name]: finalValue,
          };
        }
        return day;
      });
    });
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  return (
    <div>
      {!employeeId && (
        <div class='checkbox-wrapper-24 mx-2 pt-3'>
          <input
            type='checkbox'
            id='check-24'
            name='check'
            value='dvosedmično radno vrijeme'
            checked={selectedOptions.includes('dvosedmično radno vrijeme')}
            onChange={handleOptionChange}
          />
          <label for='check-24'>
            <span></span> Dvosedmično radno vrijeme
          </label>
        </div>
      )}
      <table className='text-sm my-5 '>
        <thead>
          <tr className='bg-[#c7ccd1da]' style={{ borderRadius: '10px' }}>
            <th>
              <h3 className='mb-0 font-bold mr-2'>Prva Sedmica </h3>
            </th>
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <>
            <tr className='mr-2'>
              <td>Dolazak</td>
              {sortedWorkingDaysInWeek.map((day, index) => (
                <td key={index + 2}>
                  <input
                    disabled={employeeId ? true : false}
                    type='text'
                    name='startShift'
                    value={day.startShift}
                    className={inputStyle + 'w-[4rem]'}
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
              ))}
            </tr>
            <tr className='mr-2'>
              <td>Odlazak</td>
              {sortedWorkingDaysInWeek.map((day, index) => (
                <td key={index}>
                  <input
                    disabled={employeeId ? true : false}
                    type='text'
                    name='endShift'
                    value={day.endShift}
                    className={inputStyle + 'w-[4rem]'}
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
              ))}
            </tr>
          </>
        </tbody>
      </table>

      {!employeeId && selectedOptions.includes('dvosedmično radno vrijeme') && (
        <table className='text-sm my-10 '>
          <thead>
            <tr className='bg-[#c7ccd1da]' style={{ borderRadius: '10px' }}>
              <th>
                <h3 className='mb-0 font-bold'>Druga sedmica</h3>
              </th>
              {daysOfWeek.map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <>
              <tr className='mr-2'>
                <td>Dolazak</td>
                {sortedWorkingDaysInSecondWeek.map((day, index) => (
                  <td key={index + 2}>
                    <input
                      disabled={employeeId ? true : false}
                      type='text'
                      name='startShift'
                      value={day.startShift}
                      className={inputStyle + 'w-[4rem]'}
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                ))}
              </tr>
              <tr className='mr-2'>
                <td>Odlazak</td>
                {sortedWorkingDaysInSecondWeek.map((day, index) => (
                  <td key={index}>
                    <input
                      type='text'
                      name='endShift'
                      value={day.endShift}
                      className={inputStyle + 'w-[4rem]'}
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                ))}
              </tr>
            </>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WeekdaysTable;
